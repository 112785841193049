<template>
  <label class="SelectSit Number" v-if="roadIndexes.includes(seatIndex) || seat.empty">
    <input type="checkbox" value="" />
    <span class="SelectSitTextBox d-flex align-items-center justify-content-center">{{ row.rowNumber || '' }}</span>
  </label>

  <label class="SelectSit Busy" v-else-if="seat.occupied || seat.inoperative">
    <input type="checkbox" value="" disabled/>
    <span class="SelectSitTextBox d-flex align-items-center justify-content-center"></span>
  </label>

  <label class="SelectSit elected-seat" v-else-if="selectedInfo">
    <input type="checkbox" value="" checked @change="unselectSeat"/>
    <span class="SelectSitTextBox d-flex align-items-center justify-content-center">
      {{ $t(`sabre.composition.${selectedInfo?.passengerType || ''}`) }}
      <br/>
      {{ selectedInfo?.passengerTypeIndex + 1 || '' }}
    </span>
  </label>

  <label class="SelectSit" :class="{'exit-line': seat.exitRow, 'standard-seat': !seat.occupied && !seat.inoperative, 'no-infant': curPsgAccInfant && seat.noInfant, 'wing-seat': isWingSeat}" v-else>
    <input type="checkbox" value="" checked @change="selectSeat"/>
    <span class="SelectSitTextBox d-flex align-items-center justify-content-center">{{ priceString }}</span>
  </label>
</template>

<script>
import gMixin from '@/utils/mixins';

export default {
  name: 'one-seat-info',
  mixins: [gMixin],
  props: {
    row: {
      type: Object,
      default: null,
    },
    seat: {
      type: Object,
      default: null,
    },
    roadIndexes: {
      type: Array,
      default: () => [],
    },
    seatData: {
      type: Array,
      default: () => [],
    },
    person: {
      type: Object,
      default: null,
    },
    wingData: {
      type: Object,
      default: null,
    },
    seatIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    curPsgAccInfant() {
      const { person } = this;
      return person.accompaniedByInfant;
    },
    selectedInfo() {
      const { seatData, row, seat } = this;
      const col = seat.number, { rowNumber } = row;
      if (!seatData) return null;
      return seatData.find((st) => st?.seatNumber === `${col}${rowNumber}`);
    },
    priceString() {
      const { seat } = this;
      const price = (seat?.offer && seat?.offer[0]?.price?.value?.toFixed(0)) || '';
      const currencyLetter = (seat.offer && seat?.offer[0]?.price?.currencyCode) || '';
      return price ? `${this.convertCurrencySymbol(currencyLetter)}${price}` : '';
    },
    isWingSeat() {
      const { wingData, row } = this;
      if (!wingData) return false;

      return +row.rowNumber >= +wingData.firstRow && +row.rowNumber <= +wingData.lastRow;
    },
  },
  methods: {
    unselectSeat() {
      const { selectedInfo } = this;
      this.$emit('unsetSeat', selectedInfo);
    },
    selectSeat() {
      const { seat, row } = this;
      this.$emit('setSeat', { seat, row, rowNumber: row.rowNumber });
    },
  },
};
</script>
